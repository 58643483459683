<template>
    <div class="full-height-layout fill">
        <router-view
            ref="routeview"
            class="full-height-layout fill"
            :transport-settings="transportSettings"
        ></router-view>
        <p class="permission-message" v-if="this.$route.params.transportSettingId && !this.isAllowedTo('getSupplierTransportSettingById')">Not allowed to see this view</p>
    </div>
</template>

<script>
import CONFIG from '@root/config'
export default {
    name: 'OrderSubmissionsTransportSettingsDetail',
    data () {
        return {
            transportSettings: null,
            callbackSettings: null,
            defaultTransportSettings: {
                willSubmitCallbacks: false,
                transportClass: null,
                transportHost: null,
                transportPort: null,
                transportUsername: null,
                transportPassword: null,
                transportDirectory: null,
                transportFormat: null,
                transportCall: null,
                transportSecret: null,
                transportToken: null,
                otherTransportClass: null,
                additionalData: null,
            },
            defaultCallbackSettings: {
                callbackClass: 'Specific\\MercurCallback',
                callbackSettings: null,
            },
        }
    },

    methods: {
        getTransportSettings () {
            if (!this.isAllowedTo('getSupplierTransportSettingById')) {
                return
            }
            this.loading = true

            const url = CONFIG.API.ROUTES.ORDER_SUBMISSION.TRANSPORT_SETTINGS.GET
                .replace('{transportSettingId}', this.$route.params.transportSettingId)
            this.addJob(url)

            this.$api.get(url).then(({ data }) => {
                const transportSettings = data.data

                if (CONFIG.TRANSPORT_SETTINGS.FORMS_OF_TRANSPORT.flatMap(({ value }) => value).filter(value => value !== 'other').includes(transportSettings.transportSettings.transportClass) === false) {
                    transportSettings.transportSettings.otherTransportClass = transportSettings.transportSettings.transportClass
                    transportSettings.transportSettings.transportClass = 'other'
                }

                transportSettings.transportSettings = {
                    ...this.defaultTransportSettings,
                    ...transportSettings.transportSettings,
                }

                this.transportSettings = transportSettings

                transportSettings.callbackSettings = {
                    ...this.defaultCallbackSettings,
                    ...transportSettings.callbackSettings,
                }

                this.callbackSettings = transportSettings.callbackSettings
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Getting transport settings failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(url)
            })
        },
    },
    created () {
        if (this.$route.params.transportSettingId) {
            this.getTransportSettings()

            return
        }

        if (this.$route.meta.type === 'new') {
            this.transportSettings = {
                settingsStatus: 'DRAFT',
                facilityIds: [],
                supplierId: null,
                transportSettings: {
                    ...this.defaultTransportSettings,
                },
                callbackSettings: {
                    ...this.defaultCallbackSettings,
                },
            }
        }
    },
}
</script>
